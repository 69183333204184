<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-6">
                    <label class="card-label">Start Date</label>
                    <fg-input v-model="formData.start_date"
                              value-format="YYYY-MM-DD"
                              type="date"
                              placeholder="Chose calendar start date"
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-6">
                    <label class="card-label">End Date</label>
                    <fg-input v-model="formData.end_date"
                              value-format="YYYY-MM-DD"
                              type="date"
                              placeholder="Chose calendar end date"
                    >

                    </fg-input>
                  </div>
                </div>
                <div class ="row" >
                  <div class="col-12">
                    <label class="card-label">Off_days</label>
                      <template class="row" v-for="(listItem,listItemIndex) in formData.off_days">
                        <div class="row team_list_items">
                          <label>Off day # {{ listItemIndex + 1 }}</label>
                          <!--                list item title-->
                          <div class="col-md-12">
                            <fg-input v-model="formData.off_days[listItemIndex]"
                                value-format="YYYY-MM-DD"
                                type="date"
                                placeholder="Chose an off date"
                            >

                            </fg-input>
                          </div>
                        </div>
                        <div class="col-sm-1 align-self-center mt-3">
                          <l-button
                            @click="removeItem(formData.off_days, listItemIndex)"
                            type="danger" size="sm">
                            <i class="fa fa-trash"></i>
                          </l-button>
                        </div>
                      </template>
                      <l-button
                        class="mt-3"
                        type="success"
                        @click="addNewItem(formData.off_days, '')"
                        size="sm">
                        <i class="fa fa-plus"></i>
                      </l-button>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <label class="card-label">Weekend days</label>
                  <div class ="weekend_days">
                    <div>
                      <input type="checkbox" id="Sunday" value="Sunday" v-model="formData.weekend_days">
                      <label for="Sunday">Sunday</label>
                    </div>
                    <div>
                      <input type="checkbox" id="Monday" value="Monday" v-model="formData.weekend_days">
                      <label for="Monday">Monday</label>
                    </div>
                    <div>
                      <input type="checkbox" id="Tuesday" value="Tuesday" v-model="formData.weekend_days">
                      <label for="Tuesday">Tuesday</label>
                    </div>
                    <div>
                      <input type="checkbox" id="Wednesday" value="Wednesday" v-model="formData.weekend_days">
                      <label for="Wednesday">Wednesday</label>
                    </div>
                    <div>
                      <input type="checkbox" id="Thursday" value="Thursday" v-model="formData.weekend_days">
                      <label for="Thursday">Thursday</label>
                    </div>
                    <div>
                      <input type="checkbox" id="Friday" value="Friday" v-model="formData.weekend_days">
                      <label for="Friday">Friday</label>
                    </div>
                    <div>
                      <input type="checkbox" id="Saturday" value="Saturday" v-model="formData.weekend_days">
                      <label for="Saturday">Saturday</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>

              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import Checkbox from "@/components/Inputs/Checkbox";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    Checkbox,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        start_date: "",
        end_date: "",
        off_days: [],
        weekend_days: [],
        is_active: 1
      },

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });
    this.id = this.$route.params['id'];

    if(this.id == undefined) {
      this.entityNotFoundError = true;
          this.$notify({
            message: "Calendar Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.$router.push("/schoold/list");
    }
    this.getItem();
  },


  methods: {
    getItem() {
      this.axios.get("calendar/get/"+  this.id).then((response) => {
        
        console.log(response.data)
        if(!response.data.start_date){
          this.editMode = false;
          this.formTitle = "Add Calendar";
        }else{
          this.formData = response.data;
          this.editMode = true;
          this.formTitle = "Edit Calendar";
        }
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Calendar Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.post("calendar/" + this.id, this.formData);
        successMessage = "Calendar Updated Successfully";
      } else {
        request = this.axios.post("calendar/" + this.id, this.formData);
        successMessage = "Calendar Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/schools/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
          if (error.response.data.errors.image !== undefined){
            this.$notify({
              message: error.response.data.errors.image[0],
              timeout: 6000,
              type: 'danger'
            })
          }
        } else {
          this.$notify({
              message: error.response.data.message,
              timeout: 6000,
              type: 'danger'
            })
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    addNewItem(listItems, initObject) {
      console.log(listItems)
      listItems.push(initObject);
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },

  }
}
</script>

<style>
.weekend_days{
  display: flex;
  justify-content: space-between;
}
.weekend_days div label {
    padding-left: 5px;
    margin: 0;
}
.weekend_days div {
    display: flex;
    align-items: center;
}
</style>
